import { BaseGridConfig, Flags, LayoutGridType, LayoutsState } from './models';
import _ from 'lodash';

export function filterOptions(
    state: LayoutsState,
    gridType: LayoutGridType
): BaseGridConfig['filterOptions'] {
    return state.selectedLayout[gridType].currentOptions.filterOptions;
}

export function columnOptions(
    state: LayoutsState,
    gridType: LayoutGridType
): BaseGridConfig['columnOptions'] {
    return state.selectedLayout[gridType].currentOptions.columnOptions;
}

export function selectedDatasetLayouts(state: LayoutsState) {
    const items = !state.selectedDatasetId
        ? state.allLayouts
        : state.allLayouts.filter(
              (x) =>
                  !x.datasetId ||
                  x.datasetId < 1 ||
                  x.datasetId === state.selectedDatasetId
          );
    return _.orderBy(items, ['isTemplate', 'name'], ['asc', 'asc']);
}

export function selectedDatasetTemplateLayouts(state: LayoutsState) {
    return selectedDatasetLayouts(state).filter((x) => x.isTemplate);
}

export function selectedDatasetUserLayouts(state: LayoutsState) {
    return selectedDatasetLayouts(state).filter((x) => !x.isTemplate);
}

function hasAtLeastOneFlag<T>(flags: Flags<T, boolean>): boolean {
    return Object.values(flags).some((x) => x === true);
}
export function hasPendingChanges(state: LayoutsState): boolean {
    const layout = state.selectedLayout;
    return (
        layout.areThereAnyUnsavedLayoutChanges ||
        hasAtLeastOneFlag(layout.fixtures.hasDifferencies) ||
        hasAtLeastOneFlag(layout.orders.hasDifferencies)
    );
}
