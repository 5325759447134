import {
    Box,
    IconButton,
    Snackbar,
    SnackbarContent,
    styled,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import LayoutControls from 'components/common/LayoutControls';
import { useAppSelector } from 'store/hooks';
import { layoutsSelectors } from 'store/feature/layoutsSlice';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { StringFormatter } from 'utils';

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    '&, *': { userSelect: 'none', gap: theme.spacing(1) },
    '& .MuiSnackbarContent-root': {
        maxWidth: '20%',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: theme.typography.pxToRem(12),
        gap: theme.spacing(1.5),
    },
    '& .MuiSnackbarContent-message': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0),
        fontSize: theme.typography.pxToRem(14),
    },
    '& .MuiSnackbarContent-action': {
        margin: theme.spacing(0),
        justifyContent: 'flex-end',
        '& *': { gap: theme.spacing(1.5) },
    },
}));

interface LayoutChangedSnackbarProps {}

const LayoutChangedSnackbar: React.FC<LayoutChangedSnackbarProps> = () => {
    const selectedLayout = useAppSelector(layoutsSelectors.selectedLayout);
    const hasPendingChanges = useAppSelector(
        layoutsSelectors.hasPendingChanges
    );
    const model = {
        id: selectedLayout.selectedLayoutId ?? '',
        name: selectedLayout.name,
        isTemplate: selectedLayout.isTemplate,
    };

    const [focused, setFocused] = useState(false);
    const [open, setOpen] = useState(hasPendingChanges);

    useEffect(() => {
        setOpen(hasPendingChanges);
    }, [selectedLayout, hasPendingChanges]);
    useEffect(() => {
        if (open && !focused) {
            const tId = setTimeout(() => {
                setOpen(false);
            }, 3000);

            return () => clearTimeout(tId);
        }
    }, [open, focused]);

    const name = StringFormatter.trimTitle(selectedLayout.name);
    const title = `Layout '${name}' has unsaved changes.`;

    return (
        <>
            {createPortal(
                <StyledSnackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={false && open} // TODO: Temporarily disabled
                    onMouseEnter={() => setFocused(true)}
                    onFocus={() => setFocused(true)}
                    onClose={(_, reason) => {
                        if (reason === 'clickaway') {
                            setFocused(false);
                        }
                    }}
                >
                    <SnackbarContent
                        message={
                            <>
                                <Box>{title}</Box>
                                <IconButton
                                    sx={(theme) => ({
                                        display: 'block',
                                        margin: theme.spacing(0, 0, 0, 1),
                                        padding: theme.spacing(0),
                                        color: theme.palette.grey[200],
                                        '*': {
                                            fontSize:
                                                theme.typography.pxToRem(18),
                                        },
                                    })}
                                    size="small"
                                    onClick={() => setOpen(false)}
                                >
                                    <Close />
                                </IconButton>
                            </>
                        }
                        action={
                            <LayoutControls
                                model={model}
                                primaryColor="secondary"
                                secondaryColor="secondary"
                                showTooltips={false}
                            />
                        }
                    ></SnackbarContent>
                </StyledSnackbar>,
                document.body
            )}
        </>
    );
};

export default LayoutChangedSnackbar;
