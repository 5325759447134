import {
    ORDER_GRID_TYPE,
    FIXTURE_GRID_TYPE,
} from '_legacy/constants/gridTypes';
import {
    getDefaultOrdersRowGroupsSorting,
    getDefaultFixturesRowGroupsSorting,
} from '../services/RowGroupsSortingService';
import {
    getDefaultOrdersRowGroups,
    getDefaultFixturesRowGroups,
} from '../services/RowGroupsService';
import layoutsInitialState from 'store/feature/layoutsSlice/initial';

export const getSelectedLayoutOptions = (selectedLayout, datasetId) => {
    if (!selectedLayout) return layoutsInitialState.selectedLayout;

    const orders =
        selectedLayout[ORDER_GRID_TYPE] &&
        JSON.parse(selectedLayout[ORDER_GRID_TYPE]);
    const fixtures =
        selectedLayout[FIXTURE_GRID_TYPE] &&
        JSON.parse(selectedLayout[FIXTURE_GRID_TYPE]);
    const commonSettings = getCommonSettings(selectedLayout.commonSettings);

    return {
        selectedLayoutId: selectedLayout.id,
        areThereAnyUnsavedLayoutChanges: false,
        commonSettings: commonSettings,
        fixtures: !fixtures
            ? layoutsInitialState.selectedLayout.fixtures
            : {
                  initialOptions: getFixturesOptions(
                      fixtures,
                      datasetId,
                      commonSettings.directionLogic.currentState
                  ),
                  currentOptions: getFixturesOptions(
                      fixtures,
                      datasetId,
                      commonSettings.directionLogic.currentState
                  ),
                  collapsedRowGroups:
                      fixtures.collapsedRowGroups ||
                      layoutsInitialState.selectedLayout.fixtures
                          .collapsedRowGroups,
                  hasRecentChanges: false,
                  hasDifferencies: {},
                  hasChanges: false,
              },
        orders: !orders
            ? layoutsInitialState.selectedLayout.orders
            : {
                  initialOptions: getOrdersOptions(
                      orders,
                      datasetId,
                      commonSettings.directionLogic.currentState
                  ),
                  currentOptions: getOrdersOptions(
                      orders,
                      datasetId,
                      commonSettings.directionLogic.currentState
                  ),
                  collapsedRowGroups:
                      orders.collapsedRowGroups ||
                      layoutsInitialState.selectedLayout.orders
                          .collapsedRowGroups,
                  hasRecentChanges: false,
                  hasDifferencies: {},
                  hasChanges: false,
              },
    };
};

const getOrdersOptions = (orders, datasetId, directionLogic) => {
    const { initialOptions } = layoutsInitialState.selectedLayout.orders;

    return {
        filterOptions: orders.filters,
        columnOptions: orders.columnState,
        shouldHighlightNewOrders:
            orders.shouldHighlightNewOrders ||
            initialOptions.shouldHighlightNewOrders,
        shouldShowConvertedOrders:
            orders.shouldShowConvertedOrders ||
            initialOptions.shouldShowConvertedOrders,
        shouldOnlyDisplayRumouredOrders:
            orders.shouldOnlyDisplayRumouredOrders ||
            initialOptions.shouldOnlyDisplayRumouredOrders,
        rowGroups: getCurrentOrDefaultValue(
            orders.rowGroups,
            getDefaultOrdersRowGroups(datasetId)
        ),
        rowGroupsSorting: getCurrentOrDefaultValue(
            orders.rowGroupsSorting,
            getDefaultOrdersRowGroupsSorting(datasetId, directionLogic)
        ),
    };
};

const getFixturesOptions = (fixtures, datasetId, directionLogic) => {
    const { initialOptions } = layoutsInitialState.selectedLayout.fixtures;

    return {
        filterOptions: fixtures.filters,
        columnOptions: fixtures.columnState,
        vesselOptions: fixtures.vesselOptions || initialOptions.vesselOptions,
        shouldOnlyDisplayRumouredFixtures:
            fixtures.shouldOnlyDisplayRumouredFixtures ||
            initialOptions.shouldOnlyDisplayRumouredFixtures,
        rowGroups: getCurrentOrDefaultValue(
            fixtures.rowGroups,
            getDefaultFixturesRowGroups(datasetId)
        ),
        rowGroupsSorting: getCurrentOrDefaultValue(
            fixtures.rowGroupsSorting,
            getDefaultFixturesRowGroupsSorting(datasetId, directionLogic)
        ),
    };
};

const getCommonSettings = (commonSettings) => {
    return !commonSettings
        ? layoutsInitialState.selectedLayout.commonSettings
        : {
              directionLogic: {
                  initialState: commonSettings.directionLogic,
                  currentState: commonSettings.directionLogic,
              },
              quantityFormat: {
                  initialState: commonSettings.quantityFormat,
                  currentState: commonSettings.quantityFormat,
              },
              defaultType: {
                  initialState: commonSettings.defaultType,
                  currentState: commonSettings.defaultType,
              },
          };
};

const getCurrentOrDefaultValue = (currentValue, defaultValue) => {
    if (!currentValue || currentValue.length === 0) {
        return defaultValue;
    }
    return currentValue;
};
