import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    styled,
    Typography,
} from '@mui/material';
import update from 'immutability-helper';
import GroupColumnValueSorting from './GroupColumnValueSorting';

const StyledAccordion = styled(Accordion)(() => ({
    border: '1px solid rgba(0, 0, 0, .125)',
    margin: '10px',
    boxShadow: 'none',
    '&:before': {
        display: 'none',
    },
    '&, & *, & .MuiTypography-root': {
        fontFamily: 'Roboto',
    },
    '& .MuiAccordionSummary-root': {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
    },
}));

const GroupColumnSorting = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const getRowGroupSortingIndex = () =>
        props.currentOptions.rowGroupsSorting.findIndex(
            (c) => c.colId === props.colId
        );

    const getRowGroupSorting = () =>
        props.currentOptions.rowGroupsSorting[getRowGroupSortingIndex()]
            .sorting;

    const currentRowGroupSorting = getRowGroupSorting();

    const [groupColumnValues, setGroupColumnValues] = React.useState(
        currentRowGroupSorting
    );

    React.useEffect(() => {
        setGroupColumnValues(currentRowGroupSorting);
    }, [currentRowGroupSorting]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const moveColumnValue = (dragIndex, hoverIndex) => {
        const newGroupColumnValues = update(groupColumnValues, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, groupColumnValues[dragIndex]],
            ],
        });

        setGroupColumnValues(newGroupColumnValues);
    };

    const updateLayout = () => {
        const rowGroupsSorting = update(props.currentOptions.rowGroupsSorting, {
            [getRowGroupSortingIndex()]: {
                $apply: function (x) {
                    return { ...x, sorting: groupColumnValues };
                },
            },
        });

        const currentLayoutOptions = {
            ...props.currentOptions,
            rowGroupsSorting,
        };

        props.onGridOptionsChanged(currentLayoutOptions);

        // changes below required just for group values update
        const currentRowGroupsIds = props.currentOptions.rowGroups
            .filter((rg) => rg.checked)
            .map((rg) => rg.colId);
        props.columnApi.setRowGroupColumns(currentRowGroupsIds);
    };

    const renderGroupColumnValues = () => {
        return groupColumnValues.map((id, index) => (
            <GroupColumnValueSorting
                key={id}
                colId={props.colId}
                id={id}
                index={index}
                moveColumnValue={moveColumnValue}
                updateLayout={updateLayout}
            />
        ));
    };

    return (
        <StyledAccordion
            square
            expanded={expanded === props.colId}
            onChange={handleChange(props.colId)}
        >
            <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <Typography>{props.header}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="ag-unselectable ag-column-drop ag-column-drop-vertical ag-last-column-drop">
                    {renderGroupColumnValues()}
                </div>
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default GroupColumnSorting;
