import {
    AgGridEvent,
    ColumnApi,
    ColumnEvent,
    FilterChangedEvent,
    GridApi,
    GridOptions,
} from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import {
    changeColumnOptions,
    changeFilterOptions,
    layoutsSelectors,
} from 'store/feature/layoutsSlice';
import { LayoutGridType } from 'store/feature/layoutsSlice/models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { GridComponent, GridComponentRef } from './models';
import { GridUtils } from './utils';

export interface GridControlContext {
    gridOptions: GridOptions;
}

const DEBOUNCE_TIME = 500;

function wrapFilterInstances(
    api: GridApi,
    filterOptions: Record<string, unknown>
): void {
    for (const colId in filterOptions) {
        const fInstance: Record<string, any> | undefined =
            api.getFilterInstance(colId)!;
        if (fInstance && !fInstance.__doesFilterPass) {
            fInstance.__doesFilterPass = fInstance.doesFilterPass;
            fInstance.doesFilterPass = (params) => {
                if (params.data.__createdInCurrentSession) {
                    return true;
                }
                return fInstance.__doesFilterPass(params);
            };
        }
    }
}

export function useGridControl(
    gridType: LayoutGridType,
    gridRef: GridComponentRef
): GridControlContext {
    const dispatch = useAppDispatch();

    const filterOptions = useAppSelector(
        (state) => layoutsSelectors.filterOptions(state, gridType),
        _.isEqual
    );
    const onFilterChanged = useMemo(
        () =>
            _.debounce<(e: FilterChangedEvent) => void>(({ api }) => {
                const changes = api.getFilterModel();
                dispatch(changeFilterOptions({ gridType, changes }));
            }, DEBOUNCE_TIME),
        [dispatch, gridType]
    );
    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.api.setFilterModel(filterOptions);
            wrapFilterInstances(gridRef.current.api, filterOptions);
        }
    }, [filterOptions, gridRef]);

    // TODO: In future - move column store-to-grid binding to this hook
    const columnOptions = useAppSelector(
        (state) => layoutsSelectors.columnOptions(state, gridType),
        _.isEqual
    );
    const onColumnsChanged = useMemo(
        () =>
            _.debounce<(e: ColumnEvent) => void>(({ columnApi }) => {
                const changes = columnApi.getColumnState();
                dispatch(changeColumnOptions({ gridType, changes }));
            }, DEBOUNCE_TIME),
        [dispatch, gridType]
    );
    const initializeGridColumns = useMemo(
        () =>
            _.debounce(({ api, columnApi }: GridComponent, columnOptions) => {
                columnApi.setColumnState(columnOptions);
                GridUtils.initializeGridColumns(columnApi, columnOptions);
                GridUtils.reloadRowGroupingIndentation(true, columnApi);
            }, DEBOUNCE_TIME),
        []
    );
    useEffect(() => {
        if (gridRef.current) {
            initializeGridColumns(gridRef.current, columnOptions);
        }
    }, [columnOptions, gridRef, initializeGridColumns]);

    return {
        gridOptions: {
            onFilterChanged,
            onSortChanged: onColumnsChanged,
            onColumnMoved: onColumnsChanged,
            onColumnResized: onColumnsChanged,
            onColumnVisible: onColumnsChanged,
            onColumnPinned: onColumnsChanged,
            onColumnRowGroupChanged: onColumnsChanged,
            onFirstDataRendered: ({ api }) => {
                api.setFilterModel(filterOptions);
                wrapFilterInstances(api, filterOptions);
            },
        },
    };
}
