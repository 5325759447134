import axios from 'axios';
import { LicenseManager } from '@ag-grid-enterprise/all-modules';

const processenv = { ...process.env };

Object.entries(processenv).forEach(sanitize);

function sanitize([k, v]) {
    if (typeof v === 'string') processenv[k] = v.trim();
}

const { NODE_ENV, REACT_APP_ENV } = processenv;

const ENV = {
    DEV: {
        apiBaseUrl: 'fort-dev.clarksons.com/api',
    },
    LOCAL: {
        apiBaseUrl: 'localhost:50001',
    },
    INT: {
        apiBaseUrl: 'fort-int.sea.live/api',
    },
};

const env = ENV[REACT_APP_ENV];

export let applicationUrl;
export let seaIdentityUrl;
export let seaClientId;
export let cloudUrl;
export let apiBaseUrl;
export let agGridLicenseKey;
export let cloudHeaderCdnUrl;
export let isDevelopment = NODE_ENV === 'development';
export let isTestMode = NODE_ENV === 'test';
export let oldLogoExpirationDate;
export let directoryClientCheckUrl;
export let intervalOfHidingOldConvertedOrdersInMinutes;

export const getConfig = async () => {
    const preventCache = new Date().getTime();
    const { data: config } = await axios.get(`/config.json?v=${preventCache}`);

    if (isDevelopment) Object.assign(config, env);

    applicationUrl = config.applicationUrl;
    seaIdentityUrl = config.seaIdentityUrl;
    seaClientId = config.seaClientId;
    cloudUrl = config.cloudUrl;
    apiBaseUrl = config.apiBaseUrl;
    agGridLicenseKey = config.agGridLicenseKey;
    cloudHeaderCdnUrl = config.cloudHeaderCdnUrl;
    oldLogoExpirationDate = config.oldLogoExpirationDate;
    directoryClientCheckUrl = config.directoryClientCheckUrl;
    intervalOfHidingOldConvertedOrdersInMinutes =
        config.intervalOfHidingOldConvertedOrdersInMinutes;

    LicenseManager.setLicenseKey(agGridLicenseKey);

    // temporary until we have setup proper build env
    if (window.location.hostname.includes('fort-int')) {
        // we do not want this in fort-int, because it messes with testing, and in general, things like instrumentationKey should be provided per env or not provided at all, right now all envs use the same instrumentationKey and make /track requests which just end up being ignored by AppInsights api as the intrumentationKey does not match the hostname;
        delete config.applicationInsights.instrumentationKey;
    }

    return config;
};
