import _ from 'lodash';
import { createAppSlice } from 'store/utils';
import legacyUserReducer from '_legacy/reducers/userReducer';
import { UserApi } from 'api';
import { UserSettingsFragment } from 'api/fragments';

export interface UserGroup {
    id: number;
    datasetId: number;
    name: string;
    fixtures: string;
    orders: string;
    commonSettings: {
        directionLogic: string;
        quantityFormat: string;
        defaultType: string;
    };
}

export type UserUiSettings = UserSettingsFragment;

export interface LayoutMenuState {
    userSectionExpanded: boolean;
    adminTemplateSectionExpanded: boolean;
    templateSectionExpanded: boolean;
}

export interface UserState {
    id: string;
    userId: number;
    username: string;
    isAdmin: boolean;
    groups: UserGroup[];
    permissions: { groupId: string; canWrite: boolean }[];
    groupId: any;
    group: UserGroup | undefined;
    gridOptions: any;
    gridData: any;
    headingDefaults: any[];
    creationIndex: any;
    dataSize: any;
    reportTitles: any[];
    themeSettings: UserUiSettings;
    uiSettings: UserUiSettings;
    userTimezone: string;
    loading: boolean;
}

const initialState: UserState = {
    id: '',
    userId: -1,
    username: '',
    isAdmin: false,
    groups: [],
    permissions: [],
    groupId: undefined,
    group: undefined,
    gridOptions: {},
    gridData: {},
    headingDefaults: [],
    creationIndex: {},
    dataSize: undefined,
    reportTitles: [],
    themeSettings: {
        newThemeEnabled: false,
        useCondensedView: false,
        useCompactDensityView: false,
        layoutsUserSectionExpanded: true,
        layoutsAdminTemplateSectionExpanded: false,
        layoutsTemplateSectionExpanded: true,
    },
    uiSettings: {
        newThemeEnabled: false,
        useCondensedView: false,
        useCompactDensityView: false,
        layoutsUserSectionExpanded: true,
        layoutsAdminTemplateSectionExpanded: false,
        layoutsTemplateSectionExpanded: true,
    },
    userTimezone: '',
    loading: false,
};

const userSlice = createAppSlice({
    name: 'user',
    initialState: (): UserState => ({ ...initialState }),
    reducers: (create) => ({
        signedIn: create.reducer<{
            username: string;
            isAdmin: boolean;
            userTimezone: string;
        }>((state, { payload }) => {
            _.assign(
                state,
                _.pick(payload, ['username', 'isAdmin', 'userTimezone'])
            );
        }),
        refreshInfo: create.asyncThunk(() => UserApi.fetchUserInfo(), {
            pending: (state) => {
                state.loading = true;
            },
            rejected: (state) => {
                state.loading = false;
            },
            fulfilled: (state, { payload }) => {
                const update = _.pick(payload, [
                    'id',
                    'userId',
                    'groups',
                    'permissions',
                    'uiSettings',
                ]);
                _.assign(state, update, { themeSettings: update.uiSettings });
                state.loading = false;
            },
        }),
        updateLayoutMenuSettings: create.asyncThunk(
            (
                {
                    userSectionExpanded,
                    adminTemplateSectionExpanded,
                    templateSectionExpanded,
                }: LayoutMenuState,
                { getState, rejectWithValue }
            ) => {
                const {
                    user: { uiSettings },
                } = getState() as { user: UserState };

                if (
                    userSectionExpanded ===
                        uiSettings.layoutsUserSectionExpanded &&
                    adminTemplateSectionExpanded ===
                        uiSettings.layoutsAdminTemplateSectionExpanded &&
                    templateSectionExpanded ===
                        uiSettings.layoutsTemplateSectionExpanded
                ) {
                    return rejectWithValue(
                        'Suppressing update, settings are the same'
                    );
                }

                return UserApi.updateUserSettings(
                    userSectionExpanded,
                    adminTemplateSectionExpanded,
                    templateSectionExpanded
                );
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                rejected: (state) => {
                    state.loading = false;
                },
                fulfilled: (state, { payload }) => {
                    _.assign(state.uiSettings, payload);
                    _.assign(state.themeSettings, payload);
                    state.loading = false;
                },
            }
        ),
    }),
    extraReducers: (create) =>
        create.addDefaultCase(
            (state, action) => legacyUserReducer(state as any, action) as any
        ),
    selectors: {
        isAdmin: (state) => state.isAdmin,
        currentDatasetId: (state): number | undefined => state.group?.datasetId,
        layoutMenu: ({ uiSettings }): LayoutMenuState => ({
            userSectionExpanded: uiSettings.layoutsUserSectionExpanded,
            adminTemplateSectionExpanded:
                uiSettings.layoutsAdminTemplateSectionExpanded,
            templateSectionExpanded: uiSettings.layoutsTemplateSectionExpanded,
        }),
    },
});

export const userSelectors = userSlice.selectors;

export const {
    signedIn: userSignedIn,
    refreshInfo: refreshUserInfo,
    updateLayoutMenuSettings,
} = userSlice.actions;

export default userSlice.reducer;
