import _ from 'lodash';
import { createAppSlice } from 'store/utils';
import { refreshUserInfo } from '../userSlice';
import { fetchSelectedLayoutId } from '_legacy/localStorage';
import {
    BaseGridConfig,
    LayoutChangePayloads,
    LayoutCollapsedRowGroupsChange,
    LayoutGridSettingsChange,
    LayoutGridType,
    LAYOUTS_SLICE_NAME,
    LayoutsState,
    SavedLayoutModel,
} from './models';
import legacyLayoutReducer from '_legacy/reducers/layoutReducer';
import * as legacyActionTypes from '_legacy/actions/actionTypes';
import {
    LayoutGridUpdateUtils,
    LayoutSerializer,
    resolveCurrentLayout,
} from './utils';
import { LayoutsApi } from 'api';
import layoutsInitialState from './initial';
import {
    columnOptions,
    filterOptions,
    hasPendingChanges,
    selectedDatasetLayouts,
    selectedDatasetTemplateLayouts,
    selectedDatasetUserLayouts,
} from './selectors';
import { AsyncThunkRejectPayload } from 'store/models';

const layoutsSlice = createAppSlice({
    name: LAYOUTS_SLICE_NAME,
    initialState: (): LayoutsState =>
        _.merge({}, layoutsInitialState, {
            selectedLayout: { selectedLayoutId: fetchSelectedLayoutId() },
        }),
    reducers: (create) => {
        return {
            selectLayout: create.reducer<{ id: string }>(
                (state, { payload }) => {
                    const selected = state.allLayouts.find(
                        (x) => x.id === payload.id
                    );
                    if (selected) {
                        state.selectedLayout = LayoutSerializer.normalize(
                            selected,
                            selected.datasetId
                        );
                    }
                }
            ),
            revertLayout: create.reducer((state) => {
                const layout = state.selectedLayout;
                LayoutGridUpdateUtils.revertLayout(layout);
            }),
            changeFilterOptions: create.reducer<
                LayoutChangePayloads['filterOptions']
            >((state, { payload: { gridType, changes } }) => {
                const selectedLayout = state.selectedLayout;
                LayoutGridUpdateUtils.processChanges(gridType, selectedLayout, {
                    filterOptions: changes,
                });
            }),
            changeColumnOptions: create.reducer<
                LayoutChangePayloads['columnOptions']
            >((state, { payload: { gridType, changes } }) => {
                const selectedLayout = state.selectedLayout;
                LayoutGridUpdateUtils.processChanges(gridType, selectedLayout, {
                    columnOptions: changes,
                });
            }),
            changeGridSettings: create.reducer<LayoutGridSettingsChange>(
                (state, { payload: { gridType, gridSettings } }) => {
                    const selectedLayout = state.selectedLayout;
                    switch (gridType) {
                        case 'fixtures':
                            LayoutGridUpdateUtils.processFixturesChanges(
                                selectedLayout,
                                gridSettings
                            );
                            break;
                        case 'orders':
                            LayoutGridUpdateUtils.processOrdersChanges(
                                selectedLayout,
                                gridSettings
                            );
                            break;
                    }
                }
            ),
            changeCollapsedRowGroups: create.asyncThunk.withTypes<{
                rejectValue: AsyncThunkRejectPayload<
                    'no_changes' | 'is_template'
                >;
            }>()(
                async (
                    {
                        gridType,
                        collapsedRowGroups,
                    }: LayoutCollapsedRowGroupsChange,
                    { getState, rejectWithValue }
                ) => {
                    const { layouts: state } = getState() as {
                        layouts: LayoutsState;
                    };

                    if (
                        !state.selectedLayout.selectedLayoutId ||
                        _.isEqual(
                            state.selectedLayout[gridType].collapsedRowGroups,
                            collapsedRowGroups
                        )
                    ) {
                        return rejectWithValue({
                            type: 'no_changes',
                            message: 'Collapsed row groups are the same',
                        });
                    }

                    if (state.selectedLayout.isTemplate) {
                        return rejectWithValue({
                            type: 'is_template',
                            message: 'Editing template is not live',
                        });
                    }

                    const selectedLayoutCopy = _.cloneDeep(
                        state.selectedLayout
                    );
                    LayoutGridUpdateUtils.revertLayout(selectedLayoutCopy);
                    selectedLayoutCopy[gridType].collapsedRowGroups =
                        collapsedRowGroups;

                    const data =
                        LayoutSerializer.serializeLayoutData(
                            selectedLayoutCopy
                        );

                    const result = await LayoutsApi.updateUserLayout({
                        ...data,
                        id: selectedLayoutCopy.selectedLayoutId!,
                        name: selectedLayoutCopy.name,
                    });

                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (
                        state,
                        {
                            payload,
                            meta: {
                                arg: { collapsedRowGroups, gridType },
                            },
                        }
                    ) => {
                        if (payload && payload.type === 'is_template') {
                            state.selectedLayout[gridType].collapsedRowGroups =
                                collapsedRowGroups;
                            state.selectedLayout[gridType].hasRecentChanges =
                                true;
                            state.selectedLayout.areThereAnyUnsavedLayoutChanges =
                                true;
                        }
                        state.loading = false;
                    },
                    fulfilled: (
                        state,
                        {
                            payload: result,
                            meta: {
                                arg: { collapsedRowGroups, gridType },
                            },
                        }
                    ) => {
                        state.selectedLayout[gridType].collapsedRowGroups =
                            collapsedRowGroups;
                        const idx = state.allLayouts.findIndex(
                            (x) => x.id === result.id
                        );
                        if (idx !== -1) {
                            state.allLayouts[idx] = {
                                ...result,
                                isTemplate: false,
                            };
                        }
                        state.loading = false;
                    },
                }
            ),
            ensureInitialLayout: create.asyncThunk(
                async (
                    {
                        name,
                        gridType,
                        initialGridConfig: { columnOptions } = {},
                    }: {
                        name: string;
                        gridType: LayoutGridType;
                        initialGridConfig?: {
                            columnOptions?: BaseGridConfig['columnOptions'];
                        };
                    },
                    { getState, rejectWithValue }
                ) => {
                    const { layouts: state } = getState() as {
                        layouts: LayoutsState;
                    };

                    if (
                        state.allLayouts.filter((l) => !l.isTemplate).length !==
                        0
                    ) {
                        return rejectWithValue('User already has layouts');
                    }

                    const datasetId = state.selectedDatasetId;
                    if (!datasetId) {
                        return rejectWithValue('Cannot create layout');
                    }

                    const layoutToCreate = _.cloneDeep(state.selectedLayout);
                    if (columnOptions) {
                        layoutToCreate[gridType].currentOptions.columnOptions =
                            columnOptions;
                    }

                    const data =
                        LayoutSerializer.serializeLayoutData(layoutToCreate);

                    const result = await LayoutsApi.createUserLayout({
                        ...data,
                        datasetId,
                        name,
                    });

                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: result }) => {
                        const newLayout: SavedLayoutModel = {
                            ...result,
                            isTemplate: false,
                        };
                        state.allLayouts.push(newLayout);
                        state.selectedLayout = LayoutSerializer.normalize(
                            newLayout,
                            newLayout.datasetId
                        );
                        state.loading = false;
                    },
                }
            ),
            createUserLayout: create.asyncThunk(
                async (
                    {
                        name,
                        gridType,
                    }: { name: string; gridType: LayoutGridType },
                    { getState, rejectWithValue }
                ) => {
                    const { layouts: state } = getState() as {
                        layouts: LayoutsState;
                    };

                    const datasetId = state.selectedDatasetId;
                    if (!datasetId) {
                        return rejectWithValue('Cannot create layout');
                    }

                    const previousLayout = state.allLayouts.find(
                        (l) => l.id === state.selectedLayout.selectedLayoutId
                    )!;
                    const result = await LayoutsApi.createUserLayout({
                        ...LayoutSerializer.serializeLayoutData(
                            state.selectedLayout
                        ),
                        datasetId,
                        name,
                    });

                    return {
                        result,
                        isFromTemplate: previousLayout.isTemplate,
                    };
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: { result } }) => {
                        const newLayout: SavedLayoutModel = {
                            ...result,
                            isTemplate: false,
                        };
                        state.allLayouts.push(newLayout);
                        state.selectedLayout = LayoutSerializer.normalize(
                            newLayout,
                            newLayout.datasetId
                        );
                        state.loading = false;
                    },
                }
            ),
            updateUserLayout: create.asyncThunk(
                async (
                    {
                        name,
                        gridType,
                    }: { name?: string; gridType: LayoutGridType },
                    { getState, rejectWithValue }
                ) => {
                    const { layouts: state } = getState() as {
                        layouts: LayoutsState;
                    };
                    const selectedLayoutId =
                        state.selectedLayout.selectedLayoutId;
                    const datasetId = state.selectedDatasetId;

                    if (!datasetId || !selectedLayoutId) {
                        return rejectWithValue('Cannot create layout');
                    }
                    name ??= state.selectedLayout.name;

                    const data = LayoutSerializer.serializeLayoutData(
                        state.selectedLayout
                    );

                    const result = await LayoutsApi.updateUserLayout({
                        ...data,
                        id: selectedLayoutId,
                        name,
                    });

                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: result }) => {
                        const newLayout: SavedLayoutModel = {
                            ...result,
                            isTemplate: false,
                        };
                        const idx = state.allLayouts.findIndex(
                            (x) => x.id === newLayout.id
                        );
                        if (idx > -1) {
                            state.allLayouts[idx] = newLayout;
                        }
                        state.selectedLayout = LayoutSerializer.normalize(
                            newLayout,
                            newLayout.datasetId
                        );
                        state.loading = false;
                    },
                }
            ),
            renameUserLayout: create.asyncThunk(
                async ({
                    id,
                    name,
                    gridType,
                }: {
                    id: string;
                    name: string;
                    gridType: LayoutGridType;
                }) => {
                    const result = await LayoutsApi.renameUserLayout(id, name);
                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: result }) => {
                        const idx = state.allLayouts.findIndex(
                            (x) => x.id === result.id
                        );
                        if (idx > -1) {
                            state.allLayouts[idx].name = result.name;
                        }
                        state.loading = false;
                    },
                }
            ),
            setUserLayoutAsPreferred: create.asyncThunk(
                async ({
                    id,
                    gridType,
                }: {
                    id: string;
                    gridType: LayoutGridType;
                }) => {
                    const result = await LayoutsApi.setUserLayoutAsPreferred(
                        id
                    );
                    return { result, gridType };
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: { result } }) => {
                        state.allLayouts.forEach((x) => {
                            x.isPreferred = x.id === result.id;
                        });
                        state.loading = false;
                    },
                }
            ),
            deleteUserLayout: create.asyncThunk(
                async ({
                    id,
                    gridType,
                }: {
                    id: string;
                    gridType: LayoutGridType;
                }) => {
                    const result = await LayoutsApi.deleteUserLayout(id);
                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: result }) => {
                        const idx = state.allLayouts.findIndex(
                            (x) => x.id === result.id
                        );
                        if (idx > -1) {
                            state.allLayouts.splice(idx, 1);
                            if (
                                state.selectedLayout.selectedLayoutId ===
                                result.id
                            ) {
                                var current = resolveCurrentLayout(state);
                                if (current) {
                                    state.selectedLayout =
                                        LayoutSerializer.normalize(
                                            current,
                                            state.selectedDatasetId
                                        );
                                }
                            }
                        }
                        state.loading = false;
                    },
                }
            ),
            createTemplateLayout: create.asyncThunk(
                async (
                    {
                        name,
                        gridType,
                    }: { name: string; gridType: LayoutGridType },
                    { getState, rejectWithValue }
                ) => {
                    const { layouts: state } = getState() as {
                        layouts: LayoutsState;
                    };
                    const selectedLayoutId =
                        state.selectedLayout.selectedLayoutId;
                    const datasetId = state.selectedDatasetId;

                    if (!selectedLayoutId || !datasetId) {
                        return rejectWithValue({
                            message: 'Cannot create layout',
                        });
                    }

                    const previousLayout = state.allLayouts.find(
                        (l) => l.id === state.selectedLayout.selectedLayoutId
                    )!;
                    const result = await LayoutsApi.createSharedLayout({
                        ...LayoutSerializer.serializeLayoutData(
                            state.selectedLayout
                        ),
                        datasetId,
                        name,
                    });

                    return {
                        result,
                        isFromTemplate: previousLayout.isTemplate,
                    };
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: { result } }) => {
                        const newLayout: SavedLayoutModel = {
                            ...result,
                            isTemplate: true,
                        };
                        state.allLayouts.push(newLayout);
                        state.selectedLayout = LayoutSerializer.normalize(
                            newLayout,
                            newLayout.datasetId
                        );
                        state.loading = false;
                    },
                }
            ),
            updateTemplateLayout: create.asyncThunk(
                async (
                    {
                        name,
                        gridType,
                    }: { name?: string; gridType: LayoutGridType },
                    { getState, rejectWithValue }
                ) => {
                    const { layouts: state } = getState() as {
                        layouts: LayoutsState;
                    };
                    const model = state.allLayouts.find(
                        (x) =>
                            x.id === state.selectedLayout.selectedLayoutId &&
                            x.isTemplate
                    );
                    const datasetId = state.selectedDatasetId;

                    if (!datasetId || !model) {
                        return rejectWithValue({
                            message: 'Cannot update layout',
                        });
                    }
                    name ??= state.selectedLayout.name;

                    const data = LayoutSerializer.serializeLayoutData(
                        state.selectedLayout
                    );

                    const result = await LayoutsApi.updateSharedLayout({
                        ...data,
                        id: model.id,
                        name,
                    });

                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: result }) => {
                        const newLayout: SavedLayoutModel = {
                            ...result,
                            isTemplate: true,
                        };
                        const idx = state.allLayouts.findIndex(
                            (x) => x.id === newLayout.id
                        );
                        if (idx > -1) {
                            state.allLayouts[idx] = newLayout;
                        }
                        state.selectedLayout = LayoutSerializer.normalize(
                            newLayout,
                            newLayout.datasetId
                        );
                        state.loading = false;
                    },
                }
            ),
            renameTemplateLayout: create.asyncThunk(
                async ({
                    id,
                    name,
                    gridType,
                }: {
                    id: string;
                    name: string;
                    gridType: LayoutGridType;
                }) => {
                    const result = await LayoutsApi.renameSharedLayout(
                        id,
                        name
                    );
                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: result }) => {
                        const idx = state.allLayouts.findIndex(
                            (x) => x.id === result.id
                        );
                        if (idx > -1) {
                            state.allLayouts[idx].name = result.name;
                        }
                        state.loading = false;
                    },
                }
            ),
            deleteTemplateLayout: create.asyncThunk(
                async ({
                    id,
                    gridType,
                }: {
                    id: string;
                    gridType: LayoutGridType;
                }) => {
                    const result = await LayoutsApi.deleteSharedLayout(id);
                    return result;
                },
                {
                    pending: (state) => {
                        state.loading = true;
                    },
                    rejected: (state) => {
                        state.loading = false;
                    },
                    fulfilled: (state, { payload: result }) => {
                        const idx = state.allLayouts.findIndex(
                            (x) => x.id === result.id
                        );
                        if (idx > -1) {
                            state.allLayouts.splice(idx, 1);
                            if (
                                state.selectedLayout.selectedLayoutId ===
                                result.id
                            ) {
                                var current = resolveCurrentLayout(state);
                                if (current) {
                                    state.selectedLayout =
                                        LayoutSerializer.normalize(
                                            current,
                                            state.selectedDatasetId
                                        );
                                }
                            }
                        }
                        state.loading = false;
                    },
                }
            ),
        };
    },
    extraReducers: (builder) =>
        builder
            .addCase(refreshUserInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(refreshUserInfo.rejected, (state) => {
                state.loading = false;
            })
            .addCase(
                refreshUserInfo.fulfilled,
                (state, { payload: { userLayouts, sharedLayouts } }) => {
                    state.allLayouts = [];
                    userLayouts.forEach((x) =>
                        state.allLayouts.push({
                            ...x,
                            isTemplate: false,
                        })
                    );

                    sharedLayouts.forEach((x) =>
                        state.allLayouts.push({
                            ...x,
                            isTemplate: true,
                            isPreferred: false,
                        })
                    );

                    state.loading = false;
                }
            )
            .addCase(legacyActionTypes.USER_GROUP_SET, (state, action: any) => {
                const datasetId = action?.group?.datasetId;
                state.selectedDatasetId = datasetId ?? null;

                const current = resolveCurrentLayout(state);
                if (current) {
                    state.selectedLayout = LayoutSerializer.normalize(
                        current,
                        datasetId
                    );
                }
            })
            .addDefaultCase(
                (state, action) =>
                    legacyLayoutReducer(state as any, action) as any
            ),
    selectors: {
        loading: (state) => state.loading,
        selectedLayout: (state) => state.selectedLayout,
        selectedLayoutId: (state) => state.selectedLayout.selectedLayoutId,
        filterOptions,
        columnOptions,
        allLayouts: (state) => state.allLayouts,
        selectedDatasetId: (state) => state.selectedDatasetId,
        selectedDatasetLayouts,
        selectedDatasetTemplateLayouts,
        selectedDatasetUserLayouts,
        hasPendingChanges,
    },
});

export const layoutsSelectors = layoutsSlice.selectors;

export const {
    selectLayout,
    revertLayout,
    changeFilterOptions,
    changeColumnOptions,
    changeGridSettings,
    changeCollapsedRowGroups,
    ensureInitialLayout,
    createUserLayout,
    updateUserLayout,
    renameUserLayout,
    setUserLayoutAsPreferred,
    deleteUserLayout,
    createTemplateLayout,
    updateTemplateLayout,
    renameTemplateLayout,
    deleteTemplateLayout,
} = layoutsSlice.actions;

export default layoutsSlice.reducer;
