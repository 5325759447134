import { PANAMAX_DIRECTION_LOGIC } from '_legacy/services/directions/DirectionServiceFactory';
import {
    LayoutCommonSettings,
    LayoutGridConfigMap,
    LayoutsState,
} from './models';
import { SHORT_HAND_QUANTITY_FORMAT } from '_legacy/modules/columns/quantity';
import { VOY } from '_legacy/components/toolPanels/commonSettings/OrderOrFixtureTypes';

const defaultFixturesOptions: LayoutGridConfigMap['fixtures'] = {
    filterOptions: {},
    columnOptions: [],
    vesselOptions: {
        minDWT: 0,
        maxDWT: 750,
    },
    rowGroups: [],
    rowGroupsSorting: [],
    shouldOnlyDisplayRumouredFixtures: false,
};
const defaultOrdersOptions: LayoutGridConfigMap['orders'] = {
    filterOptions: {},
    columnOptions: [],
    shouldHighlightNewOrders: false,
    shouldShowConvertedOrders: false,
    shouldOnlyDisplayRumouredOrders: false,
    rowGroups: [],
    rowGroupsSorting: [],
};

const defaultCommonSettings: LayoutCommonSettings = {
    directionLogic: {
        initialState: PANAMAX_DIRECTION_LOGIC,
        currentState: PANAMAX_DIRECTION_LOGIC,
    },
    quantityFormat: {
        initialState: SHORT_HAND_QUANTITY_FORMAT,
        currentState: SHORT_HAND_QUANTITY_FORMAT,
    },
    defaultType: {
        initialState: VOY,
        currentState: VOY,
    },
};

const layoutsInitialState: LayoutsState = {
    loading: false,
    selectedDatasetId: null,
    allLayouts: [],
    selectedLayout: {
        selectedLayoutId: null,
        name: 'My layout',
        isTemplate: false,
        areThereAnyUnsavedLayoutChanges: false,
        commonSettings: defaultCommonSettings,
        fixtures: {
            initialOptions: defaultFixturesOptions,
            currentOptions: defaultFixturesOptions,
            hasRecentChanges: false,
            hasDifferencies: {},
            collapsedRowGroups: [],
        },
        orders: {
            initialOptions: defaultOrdersOptions,
            currentOptions: defaultOrdersOptions,
            hasRecentChanges: false,
            hasDifferencies: {},
            collapsedRowGroups: [],
        },
    },
};

export default layoutsInitialState;
